//
// ecommerce.scss
//

// product

.search-box {
  .form-control {
    border-radius: 30px;
    padding-left: 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}

.product-list {
  li {
    a {
      display: block;
      padding: 4px 0px;
      color: $body-color;
    }
  }
}

.product-view-nav {
  &.nav-pills {
    .nav-item {
      margin-left: 4px;
    }
    .nav-link {
      width: 36px;
      height: 36px;
      font-size: 16px;
      padding: 0;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.product-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
}

// Product Details

.product-detai-imgs {
  .nav {
    .nav-link {
      margin: 7px 0px;

      &.active {
        background-color: $gray-300;
      }
    }
  }
}

.product-color {
  a {
    display: inline-block;
    text-align: center;
    color: $body-color;
    .product-color-item {
      margin: 7px;
    }
    &.active,
    &:hover {
      color: $primary;
      .product-color-item {
        border-color: $primary !important;
      }
    }
  }
}

// ecommerce cart

.visa-card {
  .visa-logo {
    line-height: 0.5;
  }

  .visa-pattern {
    position: absolute;
    font-size: 385px;
    color: rgba($white, 0.05);
    line-height: 0.4;
    right: 0px;
    bottom: 0px;
  }
}

// checkout

.checkout-tabs {
  .nav-pills {
    .nav-link {
      margin-bottom: 24px;
      text-align: center;
      background-color: $card-bg;
      box-shadow: $box-shadow;

      &.active {
        background-color: $primary;
      }
      .check-nav-icon {
        font-size: 36px;
      }
    }
  }
}

// Customer Details
.customer-avatar {
  background-color: #627ed4;
  font-size: 38px;
  color: white;
  cursor: pointer;
}

.h_color {
  color: #495057;
}

.customer-position {
  position: relative;
  top: 11px;
}

.align-item {
  align-items: center;
}

.b-margin {
  margin-bottom: 1rem;
}

.b-grouping {
  background-color: transparent;
}

.justify-space-between {
  justify-content: space-between;
}

.f-direction {
  flex-direction: column;
}

.t-align {
  text-align: center;
}

.task-btn {
  border: none;
  width: 102px;
}

.task-btn:focus,
.note-btn:focus {
  outline: none;
  box-shadow: none;
}

.task-option {
  padding: 24px;
}

.display-1 {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.btn-margin {
  margin: 2px;
}

@media (min-width: 600px) {
  .f-direction {
    flex-direction: row;
  }

  .align_text {
    text-align: left;
  }
}

@media (min-width: 800px) {
  .btn-responsive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

// notes
.new-note {
  border: none;
  outline: none;
  padding: 0;
  overflow: hidden;
  resize: none;
  display: block;
}

.note-btn:hover {
  text-decoration: none;
}

.note-e-d {
  cursor: pointer;
}

.note-e-d i {
  padding: 0.28rem;
  color: #556ee6;
  background-color: white;
  border: 1px solid #556ee6;
  transition: 1s;
}

.note-e-d i:hover {
  color: white;
  background-color: #556ee6;
}

.getDomain-result {
  min-height: 150px;
}

.getDomain-result div {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.domain-notFound {
  border: 1px solid red;
}

.domain-found {
  border: 1px solid #556ee6;
}

.domain-found-2 {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 32px 16px;
}

.domain-period {
  position: absolute;
  top: -9px;
  left: 6px;
  background-color: white;
  color: gray;
}

.check-animation {
  position: relative;
  animation: myfirst 5s linear 2s infinite alternate;
}

@keyframes myfirst {
  0% {
    font-size: 86px;
  }
  25% {
    font-size: 100px;
  }
  50% {
    font-size: 110px;
  }
  75% {
    font-size: 100px;
  }
  100% {
    font-size: 86px;
  }
}
